// gatsby-ssr.js and gatsby-browser.js files

import * as React from 'react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'
import '@fontsource/poppins/400.css' // Weight 400 with all styles included.
import '@fontsource/poppins/600.css' // Select either normal or italic.
import '@fontsource/poppins/700.css' // Select either normal or italic.
// import script from './static/scripts/common.js'

// import 'gatsby-plugin-prismic-previews/dist/styles.css'

import './src/styles/main.scss'

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider>{element}</PrismicPreviewProvider>
)

// Logs when the client route changes
// export const onRouteUpdate = ({ location, prevLocation }) => {

//     console.log("new pathname", location.pathname)
//     console.log("old pathname", prevLocation ? prevLocation.pathname : null)
// }
